<template>
  <!-- container -->
  <div id="container"> 
	
    <!-- 본문 -->
    <div id="contents">        
      <div class="inner-fixed newsDetail">
        <!-- 서브 배너 -->
        <section class="subTopArea">
          <h2>{{form.title}}</h2>
          <small>{{dateToString(form.createAt)}}</small>
        </section>
        <!-- //서브 배너 -->

        <section class="newsDetailWrap" v-html="form.info">
        </section>
        
        <ul class="newsDetail-bot f0">
          <li class="fl left" v-if="form.prevNews.id">
            <a @click="handleLink('newsView', {'query': {'id': form.prevNews.id}})"><i class="icon-arrow-left"></i> {{ $t('views.news.prev') }}</a>
            <p class="subject">{{form.prevNews.title}}</p>
          </li>
          <li class="fr right" v-if="form.nextNews.id">
            <a @click="handleLink('newsView', {'query': {'id': form.nextNews.id}})">{{ $t('views.news.next') }} <i class="icon-arrow-right"></i></a>
            <p class="subject">{{form.nextNews.title}}</p>
          </li>
        </ul>
        
        <div class="btn-cen"><a @click="handleLink('news')" class="button-go-black">{{ $t('views.news.list') }}</a></div>
      </div>
    </div>
    <!-- 본문 -->
  </div>
  <!-- //container -->
</template>

<script>
import { handleLink, dateToString } from '@/assets/js/util.js'
import instance from '@/assets/js/api.js'

export default {
  name: 'news',
  data() {
    return {
      form: {
        id: '',
        title: '',
        info: '',
        createAt: '',
        prevNews: {},
        nextNews: {},
      }
    }
  },
  methods: {
    getNewsInfo (query) {
      if(query) {
        const url = '/news'
        const method = 'get'
        let data = instance({
          url,
          method,
          params: query
        })

        data.then(responseData => {
          const {
            result,
            response
          } = responseData

          if (result) {
            this.form = {...response}
          }
        })
      } else {
        this.$alert(
          '잘못된 접근입니다.',
          {
            confirmButtonText: '확인',
            callback: () => {
              this.$router.push({ name: 'news' })
            }
          }
        )
      }
    },
    dateToString (date) { return dateToString(date, "yyyy.mm.dd") },
    handleLink (path, query) { handleLink(this.$router, path, query) }
  },
  mounted() {
    this.getNewsInfo(this.$route.query)
  },
  beforeRouteUpdate (to, from, next) {
    this.getNewsInfo(to.query)
    next()
  },
}
</script>

<style>

</style>
